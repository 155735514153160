import { __assign, __awaiter, __generator } from "tslib";
/**
 *  Created by pw on 2021/6/2 下午8:58.
 */
import { Resource } from '@ekuaibao/fetch';
import { QuerySelect } from 'ekbc-query-builder';
import { showMessage } from '@ekuaibao/show-util';
var checkinAction = new Resource('/api/checking/v2');
// 供应商 账户
var supplierAccount = new Resource('/api/supplier/v3/account');
// 供应商档案
var supplierArchive = new Resource('/api/supplier/v3/archive');
// 供应商账单
var checkingBill = new Resource('/api/checking/v3/checkingBill');
// Excel 导入结果检查
var checkingExcel = new Resource('/api/checking/v3/detail/excel/import');
// 账单详情列表
var statementDetailExtend = new Resource('/api/checking/v3/statementDetailExtend');
// 企业发票抬头列表
var payerInfo = new Resource('/api/v1/corporationset/payerinfo');
// 账期
var billPeriod = new Resource('/api/checking/v3/billPeriod');
// 数据统计
var checkingDetail = new Resource('/api/checking/v2/detail');
// 结算
var settlementAction = new Resource('/api/checking/v2/settlement');
// // 结算单配置
// const configSettlement = new Resource('/api/checking/v2/config/settlement');
// 获取子账单数据：对账单/结算单
var flows = new Resource('/api/flow/v1/flows');
// 对账结算配置列表
var checkingConfig = new Resource('/api/checking/v2/config');
var datalinkAction = new Resource('/api/v2/datalink');
// 保存合思商城字段和品类字段的映射规则
var category = new Resource('/api/checking/v3/category');
var apiSync = new Resource('/api/checking/v3');
var syncBill = new Resource("/api/checking/v3/trip");
var departmentsCheck = new Resource("/api/tobacco/v1/charge");
var deptChecking = new Resource('/api/checking/v1/deptChecking');
var orderMatchConfig = new Resource('/api/checking/v2/orderMatchConfig');
var orderMatch = new Resource('/api/checking/v2/orderMatch');
var travelBasicConfig = new Resource('/api/tpp/v2');
var getProgressAsync = new Resource('/api/v1/excel');
/**
 * 获取对账单拆分规则
 */
export function getCheckinList() {
    return checkinAction.GET('/config/split', { active: true });
}
/***
 * 保存拆分规则
 * @param params
 */
export function saveSplitConfig(params) {
    var id = params.id;
    return id ? checkinAction.PUT('/config/split', params) : checkinAction.POST('/config/split', params);
}
/**
 * 获取供应商账单配置
 */
export function getStatementConfigList() {
    return checkinAction.GET('/config/statement');
}
/**
 * 保存供应商账单配置
 * @param params
 */
export function saveStatementConfig(params) {
    return __awaiter(this, void 0, Promise, function () {
        var id;
        return __generator(this, function (_a) {
            id = params.id;
            if (id) {
                return [2 /*return*/, checkinAction.PUT('/config/statement', params)];
            }
            return [2 /*return*/, checkinAction.POST('/config/statement', params)];
        });
    });
}
/**
 * 供应商账户添加
 * @param params
 */
export function postSupplierAccount(params) {
    return supplierAccount.POST('', params);
}
/**
 * 供应商账户修改
 * @param params
 */
export function putSupplierAccount(params) {
    return supplierAccount.PUT('/$id', params);
}
/**
 * 供应商账户停用
 * @param params
 */
export function disableSupplierAccount(params) {
    return supplierAccount.PUT('/$id/disable', params);
}
/**
 * 供应商账户启用
 * @param params
 */
export function restoreSupplierAccount(params) {
    return supplierAccount.PUT('/$id/restore', params);
}
/**
 * 供应商账户列表
 * @param params
 */
export function getSupplierAccountList(params) {
    return supplierAccount.POST('/list', params);
}
/**
 * 供应商账户详情
 * @param id
 */
export function getSupplierAccount(id) {
    return supplierAccount.GET('/$id/detail', { id: id });
}
/**
 * 供应商列表
 */
export function getSupplierArchiveList(params) {
    if (params === void 0) { params = {}; }
    var query = new QuerySelect(params);
    query.desc('createTime');
    return supplierArchive.POST('/list', query.value());
}
/**
 * 供应商详情
 * @param id
 */
export function getSupplierArchive(id) {
    return supplierArchive.GET('/$id/detail', { id: id });
}
/**
 * 修改供应商 发票抬头和发票类型
 * @param params
 */
export function modifySetting(params) {
    return supplierAccount.PUT('/$id/modifySetting', params);
}
/**
 * 商城账户同步
 * @param params
 */
export function syncAccount(params) {
    return supplierAccount.PUT('/$id/syncAccount', params);
}
/**
 * 企业发票抬头列表
 */
export function getPayerInfo() {
    return payerInfo.GET();
}
/**
 * 创建供应商档案
 */
export function createSupplier(value) {
    if (value === void 0) { value = {}; }
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, supplierArchive.POST('', value)];
        });
    });
}
/**
 * 修改供应商档案
 */
export function modifySupplier(value) {
    if (value === void 0) { value = {}; }
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, supplierArchive.PUT('/$id', value)];
        });
    });
}
/**
 * 获取供应商档案详情
 */
export function getSupplierInfo(param) {
    if (param === void 0) { param = {}; }
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, supplierArchive.GET('/$id/detail', param)];
        });
    });
}
/**
 * 批量停用供应商档案
 */
export function batchDisable(ids) {
    if (ids === void 0) { ids = []; }
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, supplierArchive.PUT('/disable/batch', { ids: ids })];
        });
    });
}
/**
 * 批量启用供应商档案
 */
export function batchEnable(ids) {
    if (ids === void 0) { ids = []; }
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, supplierArchive.PUT('/restore/batch', { ids: ids })];
        });
    });
}
/**
 * 单个停用供应商档案
 */
export function disable(param) {
    if (param === void 0) { param = {}; }
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, supplierArchive.PUT('/$id/disable', param)];
        });
    });
}
/**
 * 单个启用供应商档案
 */
export function enable(param) {
    if (param === void 0) { param = {}; }
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, supplierArchive.PUT('/$id/restore', param)];
        });
    });
}
/**
 * 获取账期列表
 * */
export function getBillingPeriodList(params) {
    return __awaiter(this, void 0, void 0, function () {
        var query;
        return __generator(this, function (_a) {
            query = new QuerySelect(params);
            query.desc('name');
            return [2 /*return*/, billPeriod.POST('/list', query.value())];
        });
    });
}
/**
 * 保存账期列表
 * */
export function saveBillingPeriod(params) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, billPeriod.POST('', params)];
        });
    });
}
/**
 * 停用账期
 * */
export function disableBillingPeriod(params) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, billPeriod.PUT('/$id/disable', params)];
        });
    });
}
/**
 * 启用账期
 * */
export function restoreBillingPeriod(params) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, billPeriod.PUT('/$id/restore', params)];
        });
    });
}
/**
 * 供应商账单列表
 */
export function getCheckingBillList(params) {
    if (params === void 0) { params = {}; }
    var join = {
        join$0: 'id,warn,/checking/v3/checkingBill/log',
    };
    return checkingBill.POST('/list', params, __assign({}, join));
}
/**
 * 供应商账单详情
 * @param id
 */
export function getCheckingBill(id) {
    return checkingBill.GET('/$id', { id: id });
}
/**
 * 删除 供应商账单
 * @param id
 */
export function deleteCheckingBill(id) {
    return checkingBill.DELETE('/$id', { id: id });
}
/**
 * Excel 导入结果检查
 * @param checkingBillId
 */
export function getCheckingExcel(checkingBillId) {
    return checkingExcel.GET('/$checkingBillId/record', { checkingBillId: checkingBillId });
}
/**
 * 账单详情列表
 * @param
 */
export function getStatementDetailExtend(params, qs) {
    if (params === void 0) { params = {}; }
    return statementDetailExtend.POST('/list', params, qs);
}
/**
 * 账单详情列表
 * @param
 */
export function getAllStatementDetailExtend(params, qs) {
    if (params === void 0) { params = {}; }
    return statementDetailExtend.POST('/getAll', params, qs);
}
/**
 * 账单详情列表导出
 * @param
 */
export function exportStatementDetail(params) {
    if (params === void 0) { params = {}; }
    return statementDetailExtend.POST('/export/async', params);
}
/**
 * 供应商账单新增
 * @param params
 */
export function addCheckingBill(params) {
    if (params === void 0) { params = {}; }
    return checkingBill.POST('', params);
}
/**
 * 获取明细拆分配置列表
 */
export function getFeeConfigList() {
    return checkingConfig.POST('/detail/list');
}
export function saveFeeGenerateConfig(params) {
    var id = params.id;
    return id ? checkinAction.PUT('/config/detail', params) : checkinAction.POST('/config/detail', params);
}
/**
 * 获取企业账单详情信息
 * */
export function getCheckingBillDetail(params) {
    return checkingDetail.GET('/$id', params);
}
/***
 * 获取对账概览统计信息
 * @param params
 */
export function getCheckingStatistics(params) {
    var qs = {};
    if (params === null || params === void 0 ? void 0 : params.legalEntityId) {
        qs = { legalEntityId: params === null || params === void 0 ? void 0 : params.legalEntityId };
    }
    else if (params === null || params === void 0 ? void 0 : params.expenseDepartment) {
        qs = { expenseDepartment: params === null || params === void 0 ? void 0 : params.expenseDepartment };
    }
    return checkingDetail.GET('/statistical/$id/checking', params, qs);
}
/***
 * 获取费用统计信息
 * @param params
 */
export function getFeeTypeStatistics(params) {
    return checkingDetail.GET('/statistical/$id/legalEntity', params);
}
/***
 * 获取开票统计信息
 * @param params
 */
export function getInvoiceStatistics(params) {
    var qs = {};
    if (params === null || params === void 0 ? void 0 : params.legalEntityId) {
        qs = { legalEntityId: params === null || params === void 0 ? void 0 : params.legalEntityId };
    }
    else if (params === null || params === void 0 ? void 0 : params.expenseDepartment) {
        qs = { expenseDepartment: params === null || params === void 0 ? void 0 : params.expenseDepartment };
    }
    return checkingDetail.GET('/statistical/$id/invoice', params, qs);
}
/***
 * 发起对账
 */
export function launchChecking(params) {
    return checkingDetail.POST('/generate/$id', params);
}
/**
 * 查询生成字段账单、发起对账、发起结算的状态
 * @param params
 */
export function checkingSaveBill(params) {
    return checkingDetail.GET('/record/$checkingBillId/$type', params, undefined, { hiddenLoading: true });
}
export function checkingCheckingBillProgress(params) {
    return checkingDetail.GET('/progress/$checkingBillId', params, undefined, { hiddenLoading: true });
}
/**
 * 确认报错信息下次查询不再提示
 * @param params
 */
export function confirmError(params) {
    return checkingDetail.PUT('/record/$checkingBillId/$recordId', params);
}
/***
 * 本期不对账
 * @param ids
 */
export function unChecking(ids) {
    return checkingDetail.PUT('/disable/batch', { ids: ids });
}
/***
 * 确认本期对账
 * @param params
 */
export function confirmChecking(params) {
    return checkingBill.POST('/$id/confirm', params);
}
/***
 * 获取自对账单ids
 * @param params
 */
export function getBillIds(params) {
    var qs = {};
    if (params === null || params === void 0 ? void 0 : params.legalEntityId) {
        qs = { legalEntityId: params === null || params === void 0 ? void 0 : params.legalEntityId };
    }
    else if (params === null || params === void 0 ? void 0 : params.expenseDepartment) {
        qs = { expenseDepartment: params === null || params === void 0 ? void 0 : params.expenseDepartment };
    }
    return checkingDetail.GET('/flow/$id', params, qs);
}
/***
 * 子对账单发起对账
 * @param params: {checkingBillId: '' //对账单id, flowIds: [] // 子对账单/单据的id}
 */
export function submitBillChecking(params) {
    return checkingDetail.PUT('/submit', params);
}
/***
 * 删除子对账单
 * @param params: {checkingBillId: '' //对账单id, flowIds: [] // 子对账单/单据的id}
 */
export function deleteBillChecking(params) {
    return checkingDetail.POST('/del', params);
}
export function batchModify(params) {
    return checkingDetail.PUT('/batch', params);
}
/***
 * 结算统计信息
 * @param params {checkingBillId : ''}
 */
export function getSettlementStatisticalInfo(params) {
    return settlementAction.GET('/$checkingBillId/statistical', params);
}
/***
 * 结算统计信息
 * @param params {checkingBillId : ''}
 */
export function getSettlementBaseInfo(params) {
    return settlementAction.GET('/$checkingBillId/info', params);
}
/***
 * 结算统计信息
 * @param params {checkingBillId : '', entityId: "指定法人实体时不为空，不指定必为空"}
 */
export function generateSettlement(params) {
    return settlementAction.POST('/generate', params);
}
/***
 * 获取供应商结算单配置
 * @param params:
 */
export function getConfigSettlementList(params) {
    if (params === void 0) { params = {}; }
    return checkingConfig.POST('/settlement/list', params);
}
/***
 * 新增结算单配置
 * @param params:
 */
export function setConfigSettlement(params) {
    return checkingConfig.POST('/settlement', params);
}
/***
 * 修改结算单配置
 * @param params:
 */
export function putConfigSettlement(params) {
    return checkingConfig.PUT('/settlement/$id', params);
}
/***
 * 删除结算单配置
 * @param params:
 */
export function deleteConfigSettlement(params) {
    return checkingConfig.PUT('/settlement/$id/disable', params);
}
/***
 * 获取结算单的单据id
 * @param params
 */
export function getSettlementBIllIds(params) {
    return settlementAction.POST('/flow/$checkingBillId', params);
}
export function getBills(params) {
    return flows.POST('/search', params);
}
/***
 * 提交结算单
 * @param params : { "checkingBillId": "", "flowIds": [] }
 */
export function submitSettlement(params) {
    return settlementAction.POST('/submit', params);
}
/***
 * 删除结算单
 * @param params : { "checkingBillId": "", "flowIds": [] }
 */
export function delSettlementBill(params) {
    return settlementAction.POST('/del', params);
}
/***
 * 获取结算概览
 * @param params
 */
export function getSettlemetStatistics(params) {
    var qs = {};
    if (params === null || params === void 0 ? void 0 : params.legalEntityId) {
        qs = { legalEntityId: params === null || params === void 0 ? void 0 : params.legalEntityId };
    }
    else if (params === null || params === void 0 ? void 0 : params.expenseDepartment) {
        qs = { expenseDepartment: params === null || params === void 0 ? void 0 : params.expenseDepartment };
    }
    return checkingDetail.GET('/statistical/$id/settlement', params, qs);
}
/***
 * 根据账单ID获取品类
 * @param params : { accountId:"" }
 */
export function getCategoryByAccountId(params) {
    return checkingDetail.GET('', params);
}
/**
 * 保存(新增or修改 包括预置机酒火车在内的 和 用户自定义的）账单模板、商品品类及关联
 * @param params
 */
export function saveUserBillTemplate(params) {
    return supplierAccount.POST('/$supplierAccountId/saveUserBillTemplate', params);
}
/**
 * 获取商城字段映射规则
 * @param params
 */
export function getFieldsMapping(params) {
    return category.GET('/apiMapping/$entityId', params);
}
/**
 * 报错商城字段和品类字段映射规则
 * @param params
 */
export function saveFieldsMapping(params) {
    return category.POST('/updateApiMapping', params);
}
/**
 * 获取预置的机酒火车账单模板列表
 */
export function listSystemBillTemplates() {
    return supplierAccount.GET('/listSystemBillTemplates');
}
export function removeBillTemplate(params) {
    return supplierAccount.DELETE('/$supplierAccountId/$dataLinkEntityId/removeBillTemplate', params);
}
/**
 * 获取业务对象应用数据
 * @param params
 */
export function fetchDatalinkReferenceData(params) {
    var join = {
        join$0: 'platformId,platformId,/v2/datalink/platform',
    };
    return datalinkAction.GET('/entity/$id', __assign(__assign({}, params), join));
}
/***
 * 费用生成规则停启用
 * @param params {id:string,active:bool}
 */
export function changeDetailConfigActive(params) {
    return checkinAction.PUT('/config/detail/$id/$active', params);
}
export function refreshCheckingBills(params) {
    return checkingBill.POST('/$checkingBillId/refresh', params);
}
/**
 *  检查费用生成子对账单进度
 * @param params { checkingBillId: string }
 */
export function checkCheckingBillProgress(params) {
    return checkingBill.GET('/progress/$checkingBillId', params);
}
/**
 * 获取对账单模版可以关联的申请单模版
 */
export function getSupportRequisition() {
    return checkingConfig.GET('/support/requisition');
}
/***
 * 保存API同步对账单配置
 * @param params
 */
export function saveApiSyncConfig(params) {
    var id = params.id;
    return id ? apiSync.PUT('/syncBill/$id', params) : apiSync.POST('/syncBill', params);
}
/***
 * 删除API同步对账单配置
 * @param params
 */
export function deleteApiSyncConfig(params) {
    var id = params.id;
    return apiSync.DELETE('/syncBill/$id', { id: id });
}
/**
 * 获取API同步对账单配置列表
 */
export function getApiSyncList() {
    return apiSync.GET('/syncBill/list');
}
/**
 * 获取API预置字段
 */
export function getApiPresetFields(params) {
    var billPlatform = params.billPlatform, billOrderType = params.billOrderType;
    return apiSync.GET("/$billPlatform/$billOrderType/preset", { billPlatform: billPlatform, billOrderType: billOrderType });
}
// KA-烟草行业对账结算 获取数据
export function getDepartementChekckConfig(id) {
    return departmentsCheck.GET('', { corpId: id });
}
// 手动同步对账单
export function manuallySyncBill(params) {
    return syncBill.POST('/manual/import', params);
}
// 部门对账本期对帐完成
export function settlementSuccess(params) {
    return deptChecking.POST('/confirm', params);
}
// 获取部门对账明细汇总金额
export function getFeeDetailAmount(id) {
    return checkingDetail.GET('/feeDetailAmount/$id', { id: id });
}
// 撤回部门对账本期对帐
export function settlementCancel(params) {
    return deptChecking.POST('/cancelConfirm', params);
}
// 部门对账概览
export function depSettlement(id) {
    return deptChecking.GET('/$id', { id: id });
}
// 新增订单匹配配置
export function addOrderMatchConfig(params) {
    return orderMatchConfig.POST('', params);
}
// 获取订单匹配配置列表
export function getOrderMatchConfigList(params) {
    return orderMatchConfig.GET('/list', params);
}
// 停启用订单匹配配置
export function activeOrderMatchConfig(params) {
    var id = params.id, operate = params.operate;
    return orderMatchConfig.POST('/enable', { id: id, operate: operate });
}
// 编辑订单匹配配置
export function setOrderMatchConfig(params) {
    return orderMatchConfig.PUT('/$id', params);
}
// 获取订单匹配配置详情
export function getOrderMatchConfig(params) {
    return orderMatchConfig.GET('/$id', params);
}
// 发起订单匹配任务
export function matchTaskLaunch(params) {
    return orderMatch.POST('/matchTaskLaunch', params);
}
// 手动匹配匹配订单
export function manualMatchTask(params) {
    return orderMatch.POST('/manualMatchTask', params);
}
// 解绑订单匹配
export function matchUnbind(params) {
    return orderMatch.POST('/matchUnbind/$checkingDetailId', params);
}
// 确认订单匹配任务
export function matchConfirm(params) {
    return orderMatch.POST('/matchConfirm/$checkingBillId', params);
}
// 是否存在正在匹配订单任务
export function existMatchTask(params) {
    return orderMatch.GET('/existMatchTask/$checkingBillId', params);
}
// 是否允许进入对账
export function enterChecking(params) {
    return orderMatch.GET('/enterChecking/$id', params);
}
/**
 * 获取吉利/携程基础配置
 */
export function getApiSyncBasicConfig(params) {
    var billPlatform = params.billPlatform;
    return travelBasicConfig.GET("/travel/$billPlatform/switch", { billPlatform: billPlatform });
}
/**
 * 获取操作日志
 * @param params {checkingBillId:'对账单ID'}
 */
export function getCheckBillLog(params) {
    return checkingBill.GET('/log/$checkingBillId', params);
}
export function execlImportCheck(params) {
    return apiSync.POST("/detail/excel/check/$checkingBillId", params)
        .catch(function (err) { return showMessage.error(i18n.get(err.msg || err.errorMessage)); });
}
export function execlImportAnalysis(params) {
    return apiSync.POST("/detail/excel/analysis/$checkingBillId", params)
        .catch(function (err) {
        showMessage.error(i18n.get(err.msg || err.errorMessage));
        return err;
    });
}
export function getProgress(params) {
    return getProgressAsync.GET("/getProgress", params, null, { hiddenLoading: true })
        .catch(function (err) { return showMessage.error(i18n.get(err.msg || err.errorMessage)); });
}

var _a;
import loadable from '@loadable/component';
import { getCheckingBill, getSupportRequisition, getConfigSettlementList, getSupplierAccountList } from './setttlement-checkin-action';
export default [
    {
        id: '@settlement-checkin',
        path: '/settlement-checkin-setting',
        ref: '/',
        exact: true,
        onload: function () { return import('./setting'); },
    },
    {
        id: '@supplier-manager',
        path: '/supplier-manager',
        ref: '/',
        onload: function () { return import('./supplier-dimension'); },
    },
    {
        point: '@@layers',
        prefix: '@settlement-checkin',
        onload: function () { return import('./layer'); },
    },
    {
        id: '@supplier-account',
        path: '/supplier-account',
        ref: '/',
        onload: function () { return import('./supplier-account'); },
    },
    {
        id: '@supplier-bill',
        path: '/supplier-bill',
        ref: '/',
        onload: function () { return import('./supplier-bill'); },
    },
    {
        id: '@settlement-checking',
        path: '/settlement-checking',
        ref: '/',
        onload: function () { return import('./settlement-checking'); },
    },
    {
        id: '@settlement',
        'get:checking:bill': function (id) { return getCheckingBill(id); },
        'get:support:requisition': function () { return getSupportRequisition(); },
        'get:config:settlement:list': getConfigSettlementList,
        'get:supplier:account:list': getSupplierAccountList
    },
    {
        resource: '@settlement',
        value: (_a = {},
            _a['settlementCheckingDetail'] = loadable(function () { return import('./settlement-checking/SettlementCheckingStacker'); }),
            _a)
    },
    {
        id: '@departmentReconciliationInfo',
        path: '/department-reconciliation-info',
        ref: '/',
        onload: function () { return import('./settlement-department'); },
    },
];
